// app/providers.tsx
'use client';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

// app/providers.tsx

// app/providers.tsx

if (typeof window !== 'undefined')
{
	posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
		api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
		capture_pageview: false, // Disable automatic pageview capture, as we capture manually
		session_recording: {
			maskAllInputs: false,
			maskInputOptions: {
				password: true // Highly recommended as a minimum!!
			}
		}
	});
}

export function PHProvider({ children }: { children: React.ReactNode })
{
	if (!process.env.NEXT_PUBLIC_POSTHOG_KEY)
	{
		return <>{children}</>;
	}

	return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
