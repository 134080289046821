'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { usePostHog } from 'posthog-js/react';

import { useEffect } from 'react';

export default function PostHogPageView(): null
{
	const pathname = usePathname();
	const searchParams = useSearchParams();
	const posthog = usePostHog();
	// Track pageviews
	useEffect(() =>
	{
		if (pathname && posthog)
		{
			let url = window.origin + pathname;
			if (searchParams && searchParams.toString())
			{
				url = url + `?${searchParams.toString()}`;
			}
			posthog.capture('$pageview', {
				$current_url: url
			});
		}

		const url = window.location.href;

		return () =>
		{
			console.log('Page leave event');
			posthog.capture('$pageleave', {
				$current_url: url,
				to: window.location.href
			});
		};
	}, [pathname, searchParams, posthog]);

	return null;
}
